<template>
    <div :style="styles" class="MyAffix" @click="onClick">
        <slot>
            <!-- 默认样式，可通过插槽覆盖 -->
            <div class="MyAffix__default">
                <i class="MyAffix__default--icon iconfont icon-ego-menu"></i>
                <span class="MyAffix__default--text">{{ text || '固钉' }}</span>
            </div>
        </slot>
    </div>
</template>

<script>
    export default {
        name: "MyAffix",
        props: {
            // 偏移量
            offset: {
                type: Number,
                default: 0
            },
            // 位置
            position: {
                type: String,
                default: 'top',
                validator: (value) => {
                    let pos = ['top-left', 'top-right', 'bottom-left', 'bottom-right'];
                    return pos.includes(value);
                }
            },
            // 层级
            zIndex: {
                type: Number,
                default: 0
            },
            // 内容文字
            text: String,
            // 路由跳转path
            to: String
        },
        computed: {
            // 控制位置
            styles() {
                // position：top-left, top-right, bottom-left, bottom-right
                let {position, offset} = this;
                let [y, x] = position.split('-');
                return {
                    [y]: `${offset}px`,
                    [x]: 0,
                    zIndex: this.zIndex
                };
            }
        },
        methods: {
            // 点击事件
            onClick(event) {
                // 跳转路由
                if (this.to) {
                    let to = this.to.toLowerCase();
                    this.$router.push({path: to});
                } else {
                    // 触发click事件
                    this.$emit('click', event);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .MyAffix {
        position: fixed;
        line-height: 20px;
        &__default {
            max-width: 100px;
            color: #FFFFFF;
            background: #1677FF;
            border-radius: 50px 0 0 50px;
            font-size: 14px;
            box-shadow: 0 2px 6px 0 rgba(#000000, 0.4);
            padding: 10px 16px;
            &--icon, &--text {
                font-size: 12px;
                display: inline-block;
            }
            &--text {
                margin-left: 6px;
            }
        }
    }
</style>
